import React from 'react';

import Layout from '../components/layout';
import Menu from '../components/menu';
import Seo from '../components/seo';

const MenuPage = (props) => {

  return (
    <Layout location={props.location} themeRed={true}>
      <Seo title={'Menu'} />
      <Menu />
    </Layout>
  )
}

export default MenuPage
