import React from 'react'
import Grid from '@material-ui/core/Grid'
import ScrollToTop from 'react-scroll-to-top'
import 'react-scroll-to-top/lib/index.css'
import scrollTo from 'gatsby-plugin-smoothscroll'
import configSource from '../config.json'

const Menu = () => {
  const [menu, setMenu] = React.useState(undefined)

  React.useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch(
          'https://tosipizzeria.it/.well-known/test-config.json'
        )
        const data = await response.json()
        if (data.menu) {
          setMenu(data.menu)
        }
      } catch (e) {}
    }
    fetchData()
  }, [])

  const menuList = menu || configSource.menu
  return (
    <div>
      <div className="menu-header">
        <Grid container justify="space-around" alignItems="center">
          {menuList.map(category => (
            <Grid item xs key={category.hashtag}>
              <button
                className="menu-category"
                onClick={() => {
                  scrollTo(`#${category.hashtag}`)
                }}
              >
                {category.title}
              </button>
            </Grid>
          ))}
        </Grid>
      </div>
      <div className="menu-wrapper">
        {menuList.map(category => (
          <div className="category" key={category.hashtag}>
            <span className="anchor" id={category.hashtag} />
            <div className="category-title">
              <h2>{category.title}</h2>
              <div className="line" />
            </div>
            <Grid
              container
              justify="center"
              alignItems="flex-start"
              spacing={8}
              className="item-container"
            >
              {category.list.map(item => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  className="item"
                  key={item.name}
                >
                  <div className="name">{item.name}</div>
                  <div className="description">{item.description}</div>
                  <div className="price">{item.price}</div>
                </Grid>
              ))}
            </Grid>
          </div>
        ))}
        <ScrollToTop smooth showunder={160} />
      </div>
    </div>
  )
}

export default Menu
